import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTModalCard = _resolveComponent("KTModalCard")
  const _component_KTCreateAPIKeyModal = _resolveComponent("KTCreateAPIKeyModal")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_KTModalCard, {
      title: "Create API Key Modal Example",
      description: "Click on the below buttons to launch <br/>a new API Key creation example.",
      image: "media/illustrations/coding.png",
      "button-text": "Create API Key",
      "modal-id": "kt_modal_create_api_key"
    }),
    _createVNode(_component_KTCreateAPIKeyModal)
  ], 64))
}